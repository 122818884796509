<template>
    <div class="my-report wrapper">
        <proTitle></proTitle>
        <el-tabs v-model="activeName" @tab-click="handleClick">
            <el-tab-pane label="数据统计与分析" name="first">
                <div class="title-box flex-align-center">
                    <div class="flex-column-center">
                        <span>研修学习数据统计与分析</span>
                        <span>（{{ title }}）</span>
                    </div>
                    <el-select v-model="value" placeholder="请选择" size="mini" @change="changeValue">
                        <el-option v-for="item in options" :key="item.cycleId" :label="item.cycleName"
                            :value="item.cycleId">
                        </el-option>
                    </el-select>
                </div>
                <div class="step-box flex-align">
                    <span class="icon"></span>
                    一、本周统计
                </div>
                <el-table :data="tableData" border style="width: 100%">
                    <el-table-column label="观/听总数" align="center">
                        <template slot-scope="scope">
                            {{ scope.row.viewAndListenNumber ? scope.row.viewAndListenNumber : 0 }}
                        </template>
                    </el-table-column>
                    <el-table-column label="思总数" align="center">
                        <template slot-scope="scope">
                            {{ scope.row.thinkNumber ? scope.row.thinkNumber : 0 }}
                        </template>
                    </el-table-column>
                    <el-table-column label="问总数" align="center">
                        <template slot-scope="scope">
                            {{ scope.row.askNumber ? scope.row.askNumber : 0 }}
                        </template>
                    </el-table-column>
                    <el-table-column label="思&问相关个数" align="center">
                        <template slot-scope="scope">
                            {{ scope.row.thinkAndAskRelNumber ? scope.row.thinkAndAskRelNumber : 0 }}
                        </template>
                    </el-table-column>
                    <el-table-column label="考勤完成总次数" align="center">
                        <template slot-scope="scope">
                            {{ scope.row.joinNumber ? scope.row.joinNumber : 0 }}
                        </template>
                    </el-table-column>
                    <el-table-column label="综合得分" align="center">
                        <template slot-scope="scope">
                            {{ (scope.row.viewAndListenNumber + scope.row.thinkNumber + scope.row.askNumber +
                                scope.row.thinkAndAskRelNumber) * 20 + (scope.row.joinNumber * 10) }}
                        </template>
                    </el-table-column>
                </el-table>
                <div class="content" v-for="(item,index) in list" :key="index">
                    <div class="step-box flex-align">
                        <span class="icon"></span>
                        {{nums[index+2]+'、'+item.publishTitle }}
                    </div>
                    <div class="item-box flex-column">
                        <el-input type="textarea" :autosize="{ minRows: 10 }" v-model="item.content" readonly>
                        </el-input>
                    </div>
                </div>
            </el-tab-pane>
        </el-tabs>
    </div>
</template>
<script>
import {
    mapState
} from "vuex";
export default {
    data() {
        return {
            activeName: 'first',
            tableData: [],
            value: '',
            options: [],
            content2: '',
            content3: '',
            title: '',
            title2: '',
            title3: '',
            sort: 0,
            list:[],
            nums:{
                1:'一',
                2:'二',
                3:'三',
                4:'四',
                5:'五',
                6:'六',
                7:'七',
                8:'八',
                9:'九',
                10:'十',
            }
        }
    },
    computed: {
        ...mapState({
            currentProject: state => state.user.currentProject,
            userInfo: state => state.user.userInfo,
        })
    },
    methods: {
        handleClick() {

        },
        changeValue(val) {
            console.log('val', val);
            this.tableData = [];
            this.content2 = '';
            this.content3 = '';
            this.title2 = '';
            this.title3 = '';
            this.sort = 0;
            this.title = this.options.find(item => {
                return item.cycleId == val
            }).cycleName;
            if (val) {
                this.queryStatistics()
            } else {
                this.queryStatistics('step')
            }
            this.userReport()
        },
        async queryStatistics(step) {
            let data = {
                projectId: this.currentProject.id,
                cycleId: this.value
            }
            let resData = await this.$Api.kimi.queryStatistics(data);
            console.log('数据统计:', resData);
            if (step) {
                this.tableData.push(resData.data.wholeData)
            } else {
                this.tableData.push(resData.data.currentWeekData)
            }
        },
        async queryNewestCycleList() {
            let data = {
                projectId: this.currentProject.id
            }
            let resData = await this.$Api.kimi.queryNewestCycleList(data);
            // console.log('获取周列表:', resData);
            if (resData.data.length > 0) {
                this.options = resData.data;
                this.value = resData.data[0].cycleId;
                this.title = resData.data[0].cycleName;
                this.queryStatistics();
                this.userReport()
            }

        },
        async userReport() {
            let data = {
                periodId: this.value,
                projectId: this.currentProject.id,
                status: 1
            }
            let resData = await this.$Api.kimi.userReport(data);
            console.log('学习报告:', resData);
            this.list = resData.data.sort((a,b)=>a.publishSort - b.publishSort);
        },
        // 初始化词云
        initWordcloud() {
            let chart = this.$echarts.init(document.getElementById('wordcloud'));
            chart.setOption({
                series: [{
                    name: '高频词',
                    type: 'wordCloud',
                    shape: 'diamond', // 词云形状
                    sizeRange: [14, 32],// 字体大小范围
                    rotationRange: [-90, 90],// 文字旋转范围
                    rotationStep: 25, // 文字旋转角度
                    gridSize: 15, // 网格大小，值越大文字间的间隔越大
                    drawOutOfBound: false, // 是否允许文字超出画布边界
                    layoutAnimation: true, // 是否启用布局动画。若禁用，当文字较多时会阻塞 UI
                    textStyle: {
                        normal: {
                            color: function () {
                                return 'rgb(' + [
                                    Math.round(Math.random() * 200 + 55),
                                    Math.round(Math.random() * 200 + 55),
                                    Math.round(Math.random() * 200 + 55)
                                ].join(',') + ')';
                            }
                        }
                    },
                    emphasis: {
                        focus: 'self',
                        textStyle: {
                            textShadowBlur: 10,
                            textShadowColor: '#333'
                        }
                    },
                    data: [{
                        name: 'Farrah Abraham',
                        value: 366,
                    }, {
                        name: 'Farrah',
                        value: 255,
                    }, {
                        name: 'Abraham',
                        value: 150,
                    }, {
                        name: '高频词',
                        value: 300,
                    }, {
                        name: '词云',
                        value: 66,
                    }]
                }]
            })
        }
    },
    mounted() {
        this.queryNewestCycleList();
        // this.initWordcloud()
    }
}
</script>
<style lang="less" scoped>
// @import "../../less/table.less";
.my-report {
    padding: 20px 25px;
    box-sizing: border-box;

    .title-box {
        margin: 20px 0 40px;
        text-align: center;
        font-size: 18px;
        font-weight: bold;
        position: relative;

        .el-select {
            position: absolute;
            right: 0;
        }
    }

    .step-box {
        margin: 20px 0;

        .icon {
            display: block;
            width: 5px;
            height: 20px;
            background: #508EF9;
            border-radius: 5px;
            margin-right: 10px;
        }

        font-size: 18px;
        color: #000000;
    }

    .content {
        .step-box {
            margin: 20px 0;

            .icon {
                display: block;
                width: 5px;
                height: 20px;
                background: #508EF9;
                border-radius: 5px;
                margin-right: 10px;
            }

            font-size: 18px;
            color: #000000;
        }

        .item-box {
            // margin-top: 40px;

            .item {
                margin-bottom: 10px;
                font-size: 16px;
            }

            .item-word {
                // text-indent: 2em;
                margin-bottom: 10px;
                font-size: 16px;
            }

            /deep/ .el-textarea__inner {
                border: none
            }
        }
    }


    .wordcloud {
        box-sizing: border-box;
        width: 100%;
        height: 300px;
        // border: 1px solid #508EF9;
    }
}</style>